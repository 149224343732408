<script lang="ts" setup>
import type { Product, ProductCode } from '~/config'

const props = defineProps<{ initialProduct?: ProductCode | null }>()

const { products } = useContent()
const { visible: isDialogVisible, show: showProduct, hide: hideProduct } = useVisible()
const currentProduct = ref<Product>()

const displayProduct = (product: Product) => {
  currentProduct.value = product
  showProduct()
}

const productImg = ref<string[]>(
  products.value.flatMap((p) => [`${p.code}Artwork`, `${p.code}Icon`])
)
const { allCompleted, imgs } = useImageLoaderTracker(productImg)

if (props.initialProduct) {
  const initial = products.value.find((product) => product.code === props.initialProduct)
  if (initial) displayProduct(initial)
}

const isMounted = ref(false) //To avoid hydration warnings.
onMounted(() => (isMounted.value = true))
</script>

<template>
  <div v-if="isMounted">
    <TitleComponent text="Seguros." tagline="nunca habia sido tan fácil " />
    <div
      class="grid grid-cols-2 items-stretch justify-stretch gap-2 md:grid-cols-3 md:gap-4 lg:grid-cols-4"
    >
      <template v-for="product in products" :key="product.code">
        <div>
          <PvSkeleton v-show="!allCompleted" class="aspect-[17/9] w-full" />
          <div
            v-show="allCompleted"
            class="relative grow overflow-hidden rounded-md"
            @click="displayProduct(product)"
          >
            <img
              :id="`${product.code}Artwork`"
              :src="product.bannerUrl"
              :alt="product.name"
              class="absolute z-0 h-full w-full object-cover"
            />
            <div class="z-1 relative flex aspect-[17/9] flex-col justify-between p-[5%]">
              <img
                :id="`${product.code}Icon`"
                :src="product.iconUrl"
                :alt="product.name"
                class="size-[9vw] rounded-md md:size-[6vw] lg:size-[3.8vw] xl:size-[2.8vw]"
              />
              <span
                class="font-bold uppercase text-surface-950 sm:text-[4vw] md:text-[2.2vw] lg:text-[1.5vw] xl:text-[1.1vw]"
              >
                {{ product.alias ?? product.name }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <PvDialog
      v-if="currentProduct"
      v-model:visible="isDialogVisible"
      :closable="false"
      modal
      class="max-w-[90%] md:w-[75rem]"
    >
      <ContentProductsSumary :product-code="currentProduct.code" @close="hideProduct" />
    </PvDialog>
  </div>
</template>
