const getImgsElements = (imgIds: string | string[]) =>
  [imgIds].flat().map((img) => {
    const el = document?.getElementById(img) as HTMLImageElement
    return { src: el?.src, completed: el?.complete, el }
  })

export const useImageLoaderTracker = (refIds: Ref<string | string[]>) => {
  const imgs = ref(getImgsElements(refIds.value))

  const allCompleted = computed(() => {
    return imgs.value.every((item) => item?.completed)
  })

  //TODO: Design a better strategy to retrive updated object representation of HTMLImage elements.
  const { pause } = useIntervalFn(() => {
    imgs.value = getImgsElements(refIds.value)
    if (allCompleted.value) pause()
  }, 1000)

  return {
    imgs,
    allCompleted,
  }
}
