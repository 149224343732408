<script setup lang="ts">
import { type ProductCode } from '~/config/products'

definePageMeta({
  validate: async (route) => {
    const { products } = useContent()
    const avaliableProducts = products.value.map((p) => p.code)
    return (
      !!route.params &&
      typeof route.params === 'object' &&
      'code' in route.params &&
      (!route.params.code ||
        (typeof route.params.code === 'string' &&
          avaliableProducts.includes(route.params.code as ProductCode)))
    )
  },
})

const code = useRouteParams<ProductCode | null>('code', null)
</script>

<template>
  <div>
    <ContentHeaderMeta :product-code="code" />
    <div class="grid justify-stretch gap-12 md:gap-20">
      <ContentBanner />
      <ContentProductsList :initial-product="code" />
      <ContentHighligths />
      <ContentCompany />
    </div>
  </div>
</template>
