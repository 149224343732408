<script setup lang="ts">
const viewport = useViewport()

const isMovile = computed(() => viewport?.breakpoint.value === 'sm')

const products = ['auto', 'viajes']

const bannerImgs = computed(() =>
  products.map((p) => ({
    src: `/img/banners/banner_${p + (isMovile.value ? '_movile' : '')}.png`,
    alt: `${p}`,
  }))
)

const bannerImg = ref<string[]>(['autoBanner'])
const { allCompleted } = useImageLoaderTracker(bannerImg)

const isMounted = ref(false) //To avoid hydration warnings.
onMounted(() => (isMounted.value = true))
</script>

<template>
  <div v-if="isMounted">
    <PvSkeleton v-show="!allCompleted" class="aspect-[5665/2452] w-full" />
    <div v-show="allCompleted">
      <img
        id="autoBanner"
        :src="bannerImgs?.[0].src"
        :alt="bannerImgs?.[0].alt"
        class="w-full rounded"
        @click="navigateTo('/store/auto')"
      />

      <!-- <PvCarousel
          v-show="false"
          :value="imgs"
          :numVisible="1"
          :numScroll="1"
          circular
          :autoplayInterval="5000"
          :showNavigators="false"
        >
          <template #item="slotProps">
            <img
              :src="slotProps.data.src"
              :alt="slotProps.data.alt"
              class="w-full rounded"
              ref="bannerImg"
            />
          </template>
        </PvCarousel> -->
    </div>
  </div>
</template>
